import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../components/UI/Button";
import Line from "../../components/UI/Line";
import { Location_img, Customer, Work } from "../../assets";
import getJobById from "../../api/GET/getjobById";
import { data } from "autoprefixer";
import path from "path";

interface JobData {
  title: string;
  job_type: string;
  location: string;
  qualification: string;
  job_description: string;
  technical_skills: string[];
  roles_responsible: string[];
  looking_for_id: number;
  years_of_experince: any;
}

function JobDetails() {
  const [jobDetails, setJobDetails] = useState<JobData | null>(null);
  const navigate = useNavigate();
  const { looking_for_id } = useParams();

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const jobData: any = await getJobById(looking_for_id);
        console.log("JobDATAA", jobData);
        console.log("---------", looking_for_id);
        const selectedJob = jobData[1].find(
          (job: JobData) => job.looking_for_id === Number(looking_for_id)
        );
        console.log("Looking for iddd", looking_for_id);
        console.log("SelectedDaTAA", selectedJob);
        if (selectedJob) {
          setJobDetails(selectedJob);
        } else {
          console.log("Job not found with looking_for_id:", looking_for_id);
        }
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchJobData();
  }, [looking_for_id]);

  const navigateToApplication = () => {
    if (jobDetails) {
      navigate(`/application?jobId=${jobDetails.looking_for_id}`, {
        state: { jobTitle: jobDetails.title },
      });
    }
  };

  if (!jobDetails) {
    return <div className="text-red-500">Job details not found.</div>;
  }

  console.log(jobDetails, "check");

  // let convert = JSON.stringify(array);
  let technical_skills = jobDetails?.technical_skills;
  let roles_and_responsiblites = jobDetails?.roles_responsible;

  console.log(jobDetails.job_description);

  // let index = convert.indexOf("SQL");

  // If the element is found, remove it from the array

  console.log(technical_skills, "hello thereee");
  console.log(roles_and_responsiblites);
  return (
    <div className="p-2 pl-2 sm:pl-10 lg:pl-14 font-roboto">
      <div className="mb-6">
        <h1 className="text-lg whitespace-nowrap md:text-3xl lg:text-2xl 2xl:text-3xl font-black md:font-bold text-gray-800">
          {jobDetails.title} | {jobDetails.job_type} | {jobDetails.location}
        </h1>
        <div className="mt-2">
          <Line width="w-3/4" color="red-600" />
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-lg md:text-3xl lg:text-xl font-semibold text-gray-700 pb-1">
          Qualification
        </h1>
        <div className="text-base md:text-2xl lg:text-xl text-gray-800 pl-10">
          {jobDetails.qualification}
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-lg md:text-3xl lg:text-xl font-semibold text-gray-700 pb-1">
          Job Description
        </h1>
        <div className="text-gray-800 md:text-2xl lg:text-lg pl-10">
          {jobDetails.job_description}
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-lg md:text-3xl lg:text-xl font-semibold text-gray-700 pb-1 list-disc">
          Technical Skills
        </h1>
        <div className="text-gray-800 text-base md:text-2xl lg:text-lg pl-10">
          {/* {jobDetails.technical_skills} */}

          {technical_skills.map((skill: any) => (
            <li>{skill}</li>
          ))}
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-lg md:text-3xl lg:text-xl font-semibold text-gray-700 pb-1">
          Roles & Responsibilities
        </h1>
        <div className="text-gray-800 text-base md:text-2xl lg:text-lg font-base pl-10">
          {/* {jobDetails.roles_and_responsiblites} */}
          {roles_and_responsiblites.map((role: any) => (
            <li>{role}</li>
          ))}
        </div>
      </div>
      <div className="mb-6">
        <h1 className="text-lg md:text-3xl lg:text-xl font-semibold text-gray-700 pb-1">
          Job Overview
        </h1>
        <div className="p-4 rounded-lg shadow-lg w-64 ml-10">
          <div className="flex">
            <div className="mb-4">
              <img src={Customer} alt="job" className="" />
            </div>
            <div className="mb-4 flex flex-col pl-2">
              <p className="text-gray-700 font-extrabold md:text-2xl lg:text-lg">
                Job Title:
              </p>
              <p className="text-gray-800 text-base md:text-base ">
                {jobDetails.title}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mb-4">
              <img src={Work} alt="job" className="" />
            </div>
            <div className="mb-4 flex flex-col pl-2">
              <p className="text-gray-700 font-extrabold md:text-2xl lg:text-lg">
                Years Of Experience:
              </p>
              <p className="text-gray-800 text-base md:text-base ">
                {jobDetails.years_of_experince}
              </p>
            </div>
          </div>
          <div className="flex">
            <div className="mb-4">
              <img src={Location_img} alt="location" className="" />
            </div>
            <div className="mb-4 pl-2">
              <p className="text-gray-700 font-extrabold md:text-2xl lg:text-lg">
                Location:
              </p>
              <p className="text-gray-800 text-base ">{jobDetails.location}</p>
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-600 w-96"
              onClick={() => navigateToApplication()}
            >
              APPLY FOR JOB
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobDetails;
