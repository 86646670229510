import HasibProfile from "../../../assets/HASIB.png";
import IqbalProfile from "../../../assets/IQBAL.png";
import PrabhaProfile from "../../../assets/PRABHA.png";
import VinothProfile from "../../../assets/VSHIV.png";
import SubramaniProfile from "../../../assets/Subbhu.png";
import SanjithaProfile from "../../../assets/Sanjitha Barveen.png";
// import AbiramiProfile from "../../../assets/Abinaya.png";
// import VarunProfile from "../../../assets/VARUN.png";
// import TkProfile from "../../../assets/Tk.png";
// import AbuProfile from "../../../assets/Abu.png";
import MukilProfile from "../../../assets/MukilArasi.png";

export const productTeamProfile = [
  { id: 1, title: "Prabhakar N", imgUrl: PrabhaProfile },
  { id: 2, title: "Sanjitha Barveen", imgUrl: SanjithaProfile },
  { id: 3, title: "Subramaniyan M", imgUrl: SubramaniProfile },
  { id: 4, title: "Vinoth Siva M", imgUrl: VinothProfile },
  { id: 5, title: "Abdul Hasib", imgUrl: HasibProfile },
  { id: 6, title: "Mukil Arasi M", imgUrl: MukilProfile },
];
