import React, { useEffect } from "react";
import InputField from "../../components/UI/InputField";
import Button from "../../components/UI/Button";
import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { useParams, useSearchParams } from "react-router-dom";
import Dropdown from "../../components/UI/DropDown";
import { useState } from "react";
import { Tick } from "../../assets";
import postJobApplication from "../../api/POST/postJobApplication";
import getJobById from "../../api/GET/getJobTitle";
import TextArea from "../../components/UI/TextArea";
import { useLocation } from "react-router-dom";
import sendEmail from "../../utilities/sendEmail";

interface JobData {
  looking_for_id: number;
  title: string;
}

const Application = () => {
  // const [jobDetails, setJobDetails] = useState<JobData | null>(null);
  // const { looking_for_id } = useParams();

  const location = useLocation();
  const { jobTitle } = location.state || {};
  console.log("======", jobTitle);
  console.log("jobDEatilss", jobTitle);
  const jobIdGet = location?.search.split("=")[1];
  console.log(jobIdGet);
  const params = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [base64String, setBase64String] = useState("");
  const handleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onImgFileChange = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64String = String(reader.result).split(",")[1];
      setBase64String(base64String);
    };
  };

  const onSubmit = async (data: FormInput) => {
    try {
      console.log(data);

      console.log("response", data);
      const res = await postJobApplication({
        ...data,
        looking_for: jobIdGet,
        resume_cv: base64String,
      });
      const response = await sendEmail(data);

      if (response.status === 200) {
        console.log("Email sent successfully");
      } else {
        console.error("Failed to send email");
      }

      setIsModalOpen(true);
      console.log("Response", res);
      reset();
    } catch (error) {
      console.log("Error fetching in Application", error);
    }
  };

  const schema = z.object({
    first_name: z.string().min(4, { message: "First Name cannot be empty " }),
    last_name: z.string().min(4, { message: "Last Name cannot be empty " }),
    email: z.string().email({ message: "Please enter a valid email address" }),
    phonbe_number: z.string().refine((value) => /^[0-9]{10}$/.test(value), {
      message:
        "Phone Number must be exactly 10 digits and contain only numbers",
    }),

    address: z
      .string()
      .min(5, { message: "Address must be atleast 5 characters " }),
    country: z.string().min(2, { message: "Select a Country" }),
    zipcode: z.string().min(5, { message: "Add a valid Zipcode" }),
    X_mark: z.string().min(1, { message: "Select a Percentage" }),
    XII_mark: z.string().min(1, { message: "Select a Percentage" }),
    college: z
      .string()
      .min(2, { message: "College name must be atleast 2 characters " }),
    degree: z.string().min(2, { message: "Select your Degree" }),
    experience: z.string().min(2, { message: "Select your Experience" }),
    referal: z.string(),
    resume_cv: z
      .string()
      .min(2, { message: "Resume must be in PDF format and less than 2MB" }),
  });

  type FormInput = z.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInput>({
    resolver: zodResolver(schema),
  });

  const countryOptions = ["India", "USA", "Canada", "UK", "Australia", "China"];
  const percentageOptions = ["80-100", "60-79", "40-59", "0-39"];
  const degreeOptions = ["Bachelor's", "Master's", "Ph.D.", "Diploma"];
  const experienceOptions = [
    "Student",
    "No Experience",
    "Less than 1 Yr.",
    " 1 to 2 Yrs",
    " 2 to 5 Yrs",
    "above 5+ Yrs",
  ];

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 ">
      <div className="relative z-0">
        <div className="text-center animate-fade-in">
          <div className="text-base md:text-2xl font-semibold sm:font-bold lg:text-2xl">
            <h1 className=" text-gray-600 mt-2 whitespace-nowrap">
              Application Form for {jobTitle}
            </h1>
          </div>
          <div className="font-normal text-xs md:text-sm">
            Please fill out the details below. We will contact you as soon as
            possible.
          </div>
        </div>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className="bg-white p-4 rounded-lg shadow-md"
        >
          <div className="sm:grid  sm:grid-cols-2 gap-4">
            <Controller
              name="first_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="mb-4 sm:mb-0">
                  <InputField
                    id="first_name"
                    label="First Name"
                    type="text"
                    placeholder="First Name"
                    className=""
                    {...field}
                    inputstyle="bg-white border border-blue-200  focus:border-blue-500 hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md  focus:outline-none focus:border-blue-400"
                    labelstyle=" md:text-2xl lg:text-lg"
                  />
                  {errors.first_name && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.first_name.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            <Controller
              name="last_name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="mb-4 sm:mb-0">
                  <InputField
                    id="lastName"
                    label="Last Name"
                    type="text"
                    placeholder="Last Name"
                    {...field}
                    inputstyle="bg-white border border-blue-200   focus:bg-white  hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400"
                    labelstyle=" md:text-2xl lg:text-lg"
                  />
                  {errors.last_name && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.last_name.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="mb-4 sm:mb-0">
                  <InputField
                    id="emailId"
                    label="Email"
                    type="text"
                    placeholder="Email"
                    className=""
                    {...field}
                    inputstyle="bg-white border border-blue-200   focus:bg-white  hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400"
                    labelstyle=" md:text-2xl lg:text-lg"
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.email.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            <Controller
              name="phonbe_number"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <InputField
                    id="phoneNumber"
                    label="Phone Number"
                    type="text"
                    placeholder="Mobile Number(with code eg:+91)"
                    {...field}
                    inputstyle="bg-white border border-blue-200   focus:bg-white  hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400 focus:outline-none focus:border-blue-400"
                    labelstyle=" md:text-2xl lg:text-lg"
                  />
                  {errors.phonbe_number && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.phonbe_number.message}`}
                    </p>
                  )}
                </div>
              )}
            />

            <div className="col-span-2">
              <Controller
                name="address"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <TextArea
                      id="address"
                      label="Address"
                      type="text"
                      placeholder="Address"
                      {...field}
                    />
                    {errors.address && (
                      <p className="text-red-500 text-sm">
                        *{`${errors.address.message}`}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            <div>
              <Controller
                name="country"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <Dropdown
                      label="Country"
                      options={countryOptions}
                      placeholder="Country"
                      onChange={(e) => field.onChange(e)}
                      className="bg-white border border-blue-200  focus:border-blue-700 hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none "
                      value={field.value}
                    />
                    {errors.country && (
                      <p className="text-red-500 text-sm">
                        *{`${errors.country.message}`}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
            <div>
              <Controller
                name="zipcode"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <InputField
                      id="zipcode"
                      label="Zipcode"
                      type="text"
                      placeholder="Zipcode/Postal Code"
                      {...field}
                      inputstyle="bg-white border border-blue-200  focus:bg-white hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400"
                      labelstyle="md:text-2xl lg:text-lg"
                    />
                    {errors.zipcode && (
                      <p className="text-red-500 text-sm">
                        *{`${errors.zipcode.message}`}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            {/* <div className="col-span-2"> */}
            <Controller
              name="X_mark"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <Dropdown
                    label="Xth Mark Percentage"
                    placeholder="choose your percentage"
                    options={percentageOptions}
                    onChange={(e) => field.onChange(e)}
                    value={field.value}
                  />
                  {errors.X_mark && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.X_mark.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            {/* </div> */}

            {/* <div className="col-span-2"> */}
            <Controller
              name="XII_mark"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div>
                  <Dropdown
                    label="XIIth Mark Percentage"
                    placeholder="choose your percentage"
                    options={percentageOptions}
                    onChange={(e) => field.onChange(e)}
                    value={field.value}
                  />
                  {errors.XII_mark && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.XII_mark.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            {/* </div> */}

            {/* <div className="sm:flex sm:space-x-4 mb-4"> */}
            {/* <div className="flex-1"> */}
            <Controller
              name="college"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="mb-2">
                  <InputField
                    id="college"
                    label="College"
                    type="text"
                    placeholder="College"
                    {...field}
                    inputstyle="bg-white border border-blue-200 focus:bg-white hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400 w-full"
                    labelstyle="md:text-2xl lg:text-lg"
                  />
                  {errors.college && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.college.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            {/* </div> */}
            {/* <div className="flex-1"> */}
            <Controller
              name="degree"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <div className="mb-2">
                  <Dropdown
                    label="Degree"
                    placeholder="Degree"
                    options={degreeOptions}
                    onChange={(e) => field.onChange(e)}
                    value={field.value}
                    className="bg-white border border-blue-200 hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400 w-full"
                  />
                  {errors.degree && (
                    <p className="text-red-500 text-sm">
                      *{`${errors.degree.message}`}
                    </p>
                  )}
                </div>
              )}
            />
            {/* </div> */}
            {/* </div> */}

            <div className="col-span-2">
              <Controller
                name="experience"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <Dropdown
                      label="Experience"
                      placeholder="Experience"
                      options={experienceOptions}
                      onChange={(e) => field.onChange(e)}
                      value={field.value}
                    />
                    {errors.experience && (
                      <p className="text-red-500 text-sm">
                        *{`${errors.experience.message}`}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>

            {/* <div className="flex"> */}
            <div>
              <Controller
                name="referal"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <InputField
                      id="referral"
                      label="Referral"
                      type="text"
                      placeholder="Referral"
                      inputstyle="bg-white border border-blue-200 focus:bg-white hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400"
                      labelstyle="md:text-2xl lg:text-lg"
                      {...field}
                    />
                  </div>
                )}
              />
            </div>

            <div className="">
              <Controller
                name="resume_cv"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <div>
                    <InputField
                      id="resume"
                      label="Resume"
                      type="file"
                      placeholder="Resume"
                      {...field}
                      onChange={(e) => {
                        field.onChange(e);
                        onImgFileChange(e);
                      }}
                      inputstyle="bg-white border border-blue-200  focus:bg-white hover:shadow-md transition duration-300 ease-in-out px-4 py-2 rounded-md focus:outline-none focus:border-blue-400 "
                      labelstyle="md:text-2xl lg:text-lg"
                    />
                    {errors.resume_cv && (
                      <p className="text-gray-500">
                        *{`${errors.resume_cv.message}`}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          </div>
          <div className="flex justify-center">
            <Button
              className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-600 md:w-2/12 mt-4 transition duration-300 ease-in-out"
              type="submit"
            >
              Submit
            </Button>
          </div>
        </form>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50 "></div>
          <div className="relative z-10 bg-white p-4 rounded-md focus:outline-none focus:border-blue-400 shadow-md flex flex-col lg:w-1/3 md:w-1/2 sm:w-4/5 ">
            <div className="text-center mb-4">
              <img
                className="w-28 sm:w-40 mx-auto mb-2"
                src={Tick}
                alt="thank you"
              />
              <div className="text-black font-bold font-roboto text-xl">
                Thank you!
              </div>
              <p className="text-center text-sm">
                Your submission has been received.
              </p>
            </div>
            <div className="flex justify-center">
              <Button
                className="bg-blue-500 rounded px-3 py-1 text-white transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300"
                onClick={handleModal}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Application;
