import DeepikaProfile from "../../../assets/Dheepika-52.png";
import VigneshProfile from "../../../assets/Vignesh.png";
import VetriProfile from "../../../assets/Vetri.png";
import HariProfile from "../../../assets/Hari.png";

export const CreativesTeamProfile = [
  // { id: 1, title: "Deepika M", imgUrl: DeepikaProfile },
  { id: 2, title: "Hari Prasath R", imgUrl: HariProfile },
  { id: 3, title: "Vetrivendhan B", imgUrl: VetriProfile },
  // { id: 4, title: "Vignesh K", imgUrl: VigneshProfile },
];
